/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PsychologistReferral } from '../models/PsychologistReferral';
import { request as __request } from '../core/request';

export class PsychologistReferralService {

 /**
 * Description: Gets the users psychologistReferral.
* Operation: getMemberPsychologistReferrals
* Area: Psychologist Referral.
 * @param
 * @returns psychologist referrals Success
 * @throws ApiError
 */
public static async getPsychologistRef(
    memberId: string
): Promise<Array<PsychologistReferral>> {
    const result = await __request({
        method: 'GET',
        path: `/api/v1/members/${memberId}/psychologistreferrals`,
        errors: {
            400: `Bad Request`,
            401: `Unauthorized`,
            404: `Not Found`,
        },
    });
    return result.body;
}
}