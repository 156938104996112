
import { ReactCustomElement, ReactCustomElementWithProps } from 'web-components-with-react';
import DeliveryDistribution from './components/DeliveryDistribution/delivery-distribution';
import Charges from './components/Charges/charges';
import Campaign from './components/Campaign/campaign';
import CollapsibleInformation from './components/shared/CollapsibleInformation/collapsible-information';
import Contingent from './components/Contingent/contingent';
import InsuranceForm from './components/Insurance/insurance';
import FormItem from './components/shared/FormItem/form-item';
import Menu from './components/Menu/menu';
import TopBar from './components/TopBar/top-bar';
import EvnentDetails from './components/EventDetails/event-details';
import BurgerMenu from './components/Menu/BurgerMenu/burger-menu';
import Inbox from './components/Inbox/inbox';
import Infobox from './components/Infobox/infobox';
import Message from './components/Message/message';
import MessageSent from './components/MessageSent/message-sent';
import MissingDelivery from './components/MissingDelivery/missing-delivery';
import MyEducations from './components/MyEducation/my-educations';
import MyEvents from './components/MyEvents/my-events';
import MyInformation from './components/MyInformation/my-information';
import MySituations from './components/MySituation/my-situations';
import MyAppointments from './components/MyAppointment/my-appointments';
import NewEvents from './components/NewEvents/new-events';
import ReductionForm from './components/Reduction/reduction-form';
import Shortcuts from './components/Shortcuts/shortcuts';
import SignupForm from './components/Signup/signup-form';
import SituationChange from './components/Situation/situation-change';
import Retirement from './components/Retirement/retirement';
import UserConfirmation from './components/UserConfirmation/user-confirmation';
import './global.scss';
import reportWebVitals from './reportWebVitals';
import ContentBox from './components/ContentBox/content-box';
import InformationBox from './components/shared/InformationBox/information-box';
import Graduation from './components/Graduation/graduation';
import ReductionsList from './components/ReductionsList/reduction-list';
import District from './components/District/district';
import Balance from './components/Balance/balance';
import AdminImpersonate from './components/AdminImpersonate/admin-impersonate';
import EventRegistrationButton from './components/EventRegistrationButton/eventRegistrationButton';
import LocalUnionInformation from './components/LocalUnionInformation/localunion-information'

customElements.define("dlf-shortcuts", ReactCustomElementWithProps(Shortcuts, ['data', 'username'], false));
customElements.define("dlf-signupform", ReactCustomElementWithProps(SignupForm, ['data', 'cpr', 'memberid', 'firstname', 'lastname'], false));
customElements.define("dlf-insurance-form", ReactCustomElementWithProps(InsuranceForm, ['data', 'memberid'], false));
customElements.define("dlf-event-details", ReactCustomElementWithProps(EvnentDetails, ['data', 'memberid', 'eventid'], false));
customElements.define("dlf-myinformation", ReactCustomElementWithProps(MyInformation, ['data', 'memberid'], false));
customElements.define("dlf-missing-delivery-form", ReactCustomElementWithProps(MissingDelivery, ['data', 'memberid'], false));


customElements.define("dlf-situation-change", ReactCustomElementWithProps(SituationChange, ['data', 'memberid'], false));
customElements.define("dlf-retirement", ReactCustomElementWithProps(Retirement, ['data', 'memberid'], false));
customElements.define("dlf-collapsible-information", ReactCustomElementWithProps(CollapsibleInformation, ['data'], false));
customElements.define("dlf-form-item", ReactCustomElementWithProps(FormItem, ['data', 'memberid'], false));
customElements.define("dlf-reduction-form", ReactCustomElementWithProps(ReductionForm, ['data', 'memberid'], false));

customElements.define("dlf-top-bar", ReactCustomElementWithProps(TopBar, ['name', 'email', 'phone', 'topbartitle', 'loan', 'showuserinfo', 'memberid'], false));
customElements.define("dlf-burger-menu", ReactCustomElementWithProps(BurgerMenu, [], false));

customElements.define("dlf-inbox", ReactCustomElementWithProps(Inbox, ['data', 'memberid'], false));
customElements.define("dlf-my-message", ReactCustomElementWithProps(Message, ['data', 'memberid'], false));
customElements.define("dlf-message-sent-form", ReactCustomElementWithProps(MessageSent, ['data', 'memberid'], false));
customElements.define("dlf-district", ReactCustomElementWithProps(District, ['data', 'memberid'], false));


customElements.define("dlf-my-contingent", ReactCustomElementWithProps(Contingent, ['data', 'memberid'], false));
customElements.define("dlf-balance", ReactCustomElementWithProps(Balance, ['data', 'memberid'], false));
customElements.define("dlf-charges", ReactCustomElementWithProps(Charges, ['data', 'memberid'], false));
customElements.define("dlf-my-reduction", ReactCustomElementWithProps(ReductionsList, ['data', 'memberid'], false));

customElements.define("dlf-change-delivery-distribution-form", ReactCustomElementWithProps(DeliveryDistribution, ['data', 'memberid'], false));

customElements.define("dlf-my-events", ReactCustomElementWithProps(MyEvents, ['data'], false));
customElements.define("dlf-new-events", ReactCustomElementWithProps(NewEvents, ['data', 'memberid'], false));
customElements.define("dlf-event-registration-button", ReactCustomElementWithProps(EventRegistrationButton, ['data', 'memberid', 'eventid', 'cpr', 'firstname', 'lastname'], false));

customElements.define("dlf-user-confirmation", ReactCustomElementWithProps(UserConfirmation, ['data', 'id', 'action', 'customparams'], false));

customElements.define("dlf-my-educations", ReactCustomElementWithProps(MyEducations, ['data', 'memberid'], false));
customElements.define("dlf-my-situations", ReactCustomElementWithProps(MySituations, ['data', 'memberid'], false));
customElements.define("dlf-my-appointments", ReactCustomElementWithProps(MyAppointments, ['data', 'memberid'], false));
customElements.define("dlf-graduation", ReactCustomElementWithProps(Graduation, ['data', 'memberid'], false));

customElements.define("dlf-campaign", ReactCustomElementWithProps(Campaign, ['data', 'email'], false));
customElements.define("dlf-infobox", ReactCustomElementWithProps(Infobox, ['data'], false));
customElements.define("dlf-menu", ReactCustomElementWithProps(Menu, ['data'], false));
customElements.define("dlf-content-box", ReactCustomElementWithProps(ContentBox, ['title', 'content', 'pagetype', 'imgurl', 'width', 'height'], false));
customElements.define("dlf-information-box", ReactCustomElementWithProps(InformationBox, ['title', 'content'], false));

customElements.define("dlf-admin-impersonate", ReactCustomElementWithProps(AdminImpersonate, ['data'], false));

customElements.define("dlf-localunion-information", ReactCustomElementWithProps(LocalUnionInformation, ['data', 'memberid'], false));
// ReactDOM.render(<TestPage />,
//     document.getElementById('root')
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
