/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalUnionInformation } from '../models/LocalUnionInformation';
import { request as __request } from '../core/request';

export class LocalUnionInformationService {
    /**
     * Description: Gets information on the chairmen of the member's local union
     * @param memberId 
     * @returns LocalUnionInformation
     */
    public static async getLocalUnionInformation(memberId: string): Promise<LocalUnionInformation> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/localUnionInformation`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }
}