import style from './my-situations.module.scss'
import React, { useCallback, useEffect } from 'react';
import * as api from '../../api';
import { fetchErrorMsg } from '../../constants/common-constants';
import { getDateStr, getToday } from '../../common/dateutil'
import * as commonconstants from '../../constants/common-constants';
import { NotificationBarType } from '../shared/NotificationBarSetting/notification-bar-setting';
import { ElementHelpText, MySituationElement } from '../../models/umbracoElement';
import NewSituation from './NewSituation/new-situations';
import SituationList from './SituationList/situation-list';
import Wrapper from '../shared/Wrapper/wrapper';
import InformationBox from '../shared/InformationBox/information-box';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import List from '../shared/List/list';
import { ListItemProps } from '../shared/List/ListItem/list-item';
import OnePageFormWrapper from '../shared/OnePageFormWrapper/one-page-form-wrapper';
import ButtonZone from '../shared/ButtonZone/button-zone';
import FlowButton from '../shared/Buttons/FlowButton/flow-button';

interface MySituationProps {
    memberid: string;
    data: string;
}
function MySituation(props: MySituationProps) {
    const [elementdata] = React.useState<MySituationElement>(props.data ? JSON.parse(props.data) : {});
    const [isCreateNewOpen, setIsCreateNewOpen] = React.useState(false);
    const [situations, setSituations] = React.useState<api.Situation[]>();
    const [district, setDistrict] = React.useState<string>();
    const [previousSituations, setPreviousSituations] = React.useState<api.Situation[]>();
    const [pendingSituations, setPendingSituations] = React.useState<api.Situation[]>();
    const [chosenSituation, setChosenSituation] = React.useState<api.Situation>();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [isReadonly, setIsReadonly] = React.useState(false);

    const getData = useCallback(async () => {
        try {
            const myinfo: api.Member = await api.MembersService.getMemberById(props.memberid);
            if (!myinfo.situations)
                throw new Error("Nuværende situation kunne ikke findes");

            setDistrict(myinfo.district?.districtId);
            setPreviousSituations(getPrevious(myinfo.situations));
            setPendingSituations(getPending(myinfo.situations));
            setSituations(getActive(myinfo.situations));

        } catch (error) {
            setErrorMsg(fetchErrorMsg)
            console.log(error)
        }
    }, [props.memberid])

    const getPrevious = (situations?: api.Situation[]) => {
        return !situations ? [] : situations.filter(x => x.enddate && new Date(x.enddate) < getToday());
    }

    const getPending = (situations?: api.Situation[]) => {
        return !situations ? [] : situations.filter(x =>
            (x.type?.situationTypeNo !== commonconstants.unemployedEnumNo
                && x.type?.situationTypeNo !== commonconstants.pensionistEnumNo
                && x.type?.situationTypeNo !== commonconstants.retirementRecipientEnumNo
                && x.type?.situationTypeNo !== commonconstants.studentEnumNo) //Student sitautations should end up in pending because a lot of migrated student situtations are missing education information
            && !x.workLocation && !x.educationInstitution
            && (!x.enddate || new Date(x.enddate) >= getToday()));
    }

    const getActive = (situations?: api.Situation[]) => {
        return !situations ? [] : situations.filter(x => (
            (x.type?.situationTypeNo === commonconstants.unemployedEnumNo ||
                x.type?.situationTypeNo === commonconstants.pensionistEnumNo ||
                x.type?.situationTypeNo === commonconstants.retirementRecipientEnumNo ||
                x.type?.situationTypeNo === commonconstants.studentEnumNo)
            || (x.workLocation || x.educationInstitution))
            && (!x.enddate || (x.enddate && new Date(x.enddate) >= getToday())))
    }

    useEffect(() => {
        setLoading(true);
        const call = async () => {
            await getData();
        }
        call().finally(() => { setLoading(false) });
    }, [getData]);



    const openDetail = (situation: api.Situation) => {
        setChosenSituation(situation);
        setIsReadonly(true);
    }

    const openCreateNew = async () => {
        setIsCreateNewOpen(true);

    }
    const setCreateNewOpen = (open: boolean, refresh: boolean) => {
        if (refresh) {
            setLoading(true);
            getData().finally(() => { setLoading(false) });
        }
        setIsCreateNewOpen(open)
    }

    const backToList = () => {
        setIsReadonly(false);
        setChosenSituation(undefined);

    }
    const wrapperProps: { active: ElementHelpText, previous: ElementHelpText, pending: ElementHelpText } = React.useMemo(() => {
        const base = {
            contentTypeAlias: "",
            id: ""
        }
        return {
            pending: {
                ...base,
                title: elementdata.pendingSituationsTitle,
                helpText: elementdata.pendingSituationsHelpText
            },
            active: {
                ...base,
                title: elementdata.title,
                helpText: elementdata.helpText
            },
            previous: {
                ...base,
                title: elementdata.previousSituationsTitle,
                helpText: elementdata.previousSituationHelpText
            }
        }
    }, [elementdata])

    const readonlyItems: ListItemProps[] = React.useMemo(() => {

        if (!chosenSituation) return [];
        let items: ListItemProps[] = [{
            label: "Situationtype",
            childrenitems: [chosenSituation?.type?.name || ""]
        }];
        const injobItems = [
            {
                label: "Arbejdsplads",
                childrenitems: [chosenSituation?.workLocation?.name || (chosenSituation?.workLocation?.workLocationId ? "" : elementdata.workplacePendingApproval)]
            },
            {
                label: "Ansættelsesdato",
                childrenitems: [chosenSituation?.startdate ? getDateStr(new Date(chosenSituation.startdate)) : ""]
            },
            {
                label: "Stillingstype",
                childrenitems: [chosenSituation?.jobType?.name || ""]
            },
            {
                label: "Stillingsbetegnelse",
                childrenitems: [chosenSituation?.jobTitle?.name || ""]
            }];
        const studentItems = [{
            label: "Uddannelsessted",
            childrenitems: [chosenSituation?.educationInstitution?.displayName || ""]
        },
        {
            label: "Uddannelsesretning",
            childrenitems: [chosenSituation?.educationOrientation?.name || ""]
        },
        {
            label: "Linjefag",

            childrenitems: chosenSituation?.educationMainSubject?.map(x => x.name || "") || [""]
        },
        {
            label: "Start dato",
            childrenitems: [chosenSituation?.startdate ? getDateStr(new Date(chosenSituation.startdate)) : ""]
        }];

        if (chosenSituation?.type?.situationTypeNo === commonconstants.inJobEnumNo) {
            items = [...items, ...injobItems];
        }
        else if (chosenSituation?.type?.situationTypeNo === commonconstants.studentEnumNo) {
            items = [...items, ...studentItems];
        }
        else {
            items = [...items, {
                label: "Start dato",
                childrenitems: [chosenSituation?.startdate ? getDateStr(new Date(chosenSituation.startdate)) : ""]
            }];
        }
        if (chosenSituation?.enddate || chosenSituation?.expectedGraduationDate)
            items = [...items, {
                label: chosenSituation?.enddate ? "Slutdato" : "Forventet dimission",
                childrenitems: [chosenSituation?.enddate ? getDateStr(new Date(chosenSituation.enddate)) : (
                    chosenSituation?.expectedGraduationDate ? getDateStr(new Date(chosenSituation.expectedGraduationDate)) : "")]
            }]

        return items;

    }, [chosenSituation, elementdata])



    return (<>
        {isReadonly ?
            <Wrapper
                properties={{} as ElementHelpText}
                backBarSettings={elementdata.showBackBar ? { backLabel: "Tilbage", onBackClick: () => backToList() } : undefined}
                bottomZone={!elementdata.showBackBar ? <ButtonZone>
                    {<FlowButton className={style.onebutton} isBack={true} onClick={backToList}> {"Tilbage"}</FlowButton>}
                </ButtonZone> : undefined}
                children={<OnePageFormWrapper hasButton={!elementdata.showBackBar} className={`${style["col-6"]} ${style["col-10-lg"]} ${style["col-12-md"]} ${style["col-12-sm"]} ${style.readonlylist}`} title={''} children={<List listItems={readonlyItems} />}></OnePageFormWrapper>}
            ></Wrapper>

            : !isCreateNewOpen ? <>
                <Wrapper
                    properties={{} as ElementHelpText}
                    children={<div className={style.createnewbutton}>
                        {pendingSituations && pendingSituations.length > 0 ? <InformationBox title="" content={elementdata.hasPendingSituationText} />
                            : <CTAButton color={"var(--themecolor)"} onClick={openCreateNew} disabled={pendingSituations && pendingSituations.length > 0}>{elementdata.createNewButtonLabel || "Opret ny situation"}</CTAButton>
                        }
                    </div>}
                ></Wrapper>
                {
                    pendingSituations && pendingSituations.length > 0 && <Wrapper
                        loading={loading}
                        children={!loading && !isCreateNewOpen &&
                            <SituationList situations={pendingSituations} showButtonLabel={elementdata.showButtonLabel} openDetail={openDetail} ></SituationList>
                        }
                        properties={wrapperProps.pending}></Wrapper>
                }
                {
                    situations && situations.length > 0 && <Wrapper
                        loading={loading}
                        notification={errorMsg ? { message: errorMsg, type: NotificationBarType.Danger, onClick: () => setErrorMsg("") } : undefined}
                        children={!loading && !isCreateNewOpen &&
                            <SituationList situations={situations} showButtonLabel={elementdata.showButtonLabel} openDetail={openDetail} ></SituationList>
                        }
                        properties={wrapperProps.active} ></Wrapper>
                }
                <Wrapper
                    loading={loading}
                    children={!loading && !isCreateNewOpen &&
                        <SituationList situations={previousSituations} showButtonLabel={elementdata.showButtonLabel} openDetail={openDetail} ></SituationList>
                    }
                    properties={wrapperProps.previous} ></Wrapper>
            </>
                : <NewSituation situations={situations || []} district={district} elementdata={elementdata} setIsOpen={setCreateNewOpen} memberid={props.memberid} />
        }</>

    )
}

export default MySituation;