/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appointment } from '../models/Appointment';
import { request as __request } from '../core/request';

export class AppointmentService {

    /**
     * Description: Gets upcoming and past appointments for a member by their ID.
 * Operation: GetMemberAppointments.
 * Area: Appointments.
     * @param memberId ID of member that needs to be fetched.
     * @returns Appointment Success
     * @throws ApiError
     */
    public static async GetMemberAppointments(
        memberId: string,
    ): Promise<Array<Appointment>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/members/${memberId}/appointments`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Description: Book an appointment for the current member.
 * Operation: membersBookAppointment.
 * Area: Appointments.
     * @param memberId ID of member.
     * @returns BookAppointment Success
     * @throws ApiError
     */
    public static async membersBookAppointment(
        memberId: string,
        appointment: Appointment,
        message: string,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/bookappointment`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
            body: {
                AppointmentId: appointment.id,
                message: message
            }
        });
        return result.body;
    }

    /**
     * Description: Cancel an upcoming appointment for the current member.
 * Operation: membersCancelAppointment.
 * Area: Appointments.
     * @param memberId ID of member.
     * @returns CancelAppointment Success
     * @throws ApiError
     */
    public static async membersCancelAppointment(
        memberId: string,
        appointment: Appointment,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/members/${memberId}/cancelappointment`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
            body: {
                appointmentId: appointment.id
            }
        });
        return result.body;
    }

    /**
 * Description: Gets available appointments.
* Operation: getAvailableAppointments.
* Area: Appointments.
 * @param
 * @returns CancelAppointment Success
 * @throws ApiError
 */
    public static async getAvailableAppointments(
    ): Promise<Array<Appointment>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/appointments/availableappointments`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
        return result.body;
    }
}