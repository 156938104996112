import React, { useEffect } from 'react';
import * as api from '../../api';
import { getNewErrorList } from '../../common/getNewErrorList';
import * as commonconstants from '../../constants/common-constants';
import * as signupConstants from '../../constants/signup-constants';
import { DropdownItem } from '../../models/dropdownItem';
import { defaultSignUpModel } from '../../models/signupModel';
import { FormSteps, FormError } from '../../models/step';
import { deliveryOptions } from '../../models/subscriptionModel';
import { ConsentListItem, SignupElement } from '../../models/umbracoElement';
import Confirmation from '../shared/Confirmation/confirmation';
import ConsentForm from '../shared/ConsentForm/consent-form';
import SignupFifthStep from './SignupFifth/signup-fifth-step';
import SignupFirstStep from './SignupFirst/signup-first-step';
import SignupFourthStep from './SignupFourth/signup-fourth-step';
import SignupThirdStep from './SignupThird/signup-third-step';
import Wizard from '../shared/Wizard/wizard';
import PreSignupStep from './PreSignup/pre-signup-step';
interface SignupFormProps {
    data: any
    cpr: string,
    memberid?: string,
    firstname: string,
    lastname: string
}
const arrearsToken = "#{OWED_AMOUNT}#";
function SignupForm(props: SignupFormProps) {
    const signupElement: SignupElement = JSON.parse(props.data);
    const [signUpModel, setSignUpModel] = React.useState({ ...defaultSignUpModel, cpr: props.cpr, firstname: props.firstname, lastname: props.lastname });
    const [chosenWork, setChosenWork] = React.useState<DropdownItem | undefined>();
    const [jobtitles, setJobtitles] = React.useState<DropdownItem[]>([]);
    const [jobtypes, setJobTypes] = React.useState<DropdownItem[]>([]);
    const [situationTypes, setSituationTypes] = React.useState<DropdownItem[]>([]);
    const [workplaces, setWorkplaces] = React.useState<DropdownItem[]>([]);
    const [contactPrefs, setContactPrefs] = React.useState<DropdownItem[]>([]);
    const [educationalInstitutions, setEducationalInstitutions] = React.useState<DropdownItem[]>([]);
    const [educationalOrientations, setEducationalOrientations] = React.useState<DropdownItem[]>([]);
    const [mainsubjects, setMainsubjects] = React.useState<DropdownItem[]>([]);
    const [countries, setCountries] = React.useState<DropdownItem[]>([]);
    const [municipalities, setMunicipalities] = React.useState<DropdownItem[]>([]);
    const [errorList, setErrorList] = React.useState<FormError[]>([]);
    const [isStudent, setIsStudent] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [workInfoRequired, setWorkInfoRequired] = React.useState([signupConstants.situationtypeId])
    const [educationInfoRequired, setEducationInfoRequired] = React.useState([signupConstants.startdate])
    const [magazines, setMagazines] = React.useState<api.Magazine[]>([]);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [lastStepNo, setLastStepNo] = React.useState<number>();
    const [showErrorNotification, setShowErrorNotification] = React.useState(false);
    const [addedWorkplace, setAddedWorkplace] = React.useState<string>()
    const [requireFirstStepData, setRequireFirstStepData] = React.useState([signupConstants.mail,
    signupConstants.municipalityId,
    signupConstants.lastname,
    signupConstants.firstname,
    signupConstants.street,
    signupConstants.number,
    signupConstants.zip,
    signupConstants.cpr,
    signupConstants.mobilephone,
    signupConstants.city]);

    /* TODO: change here when we know more about payment method Task #91795*/
    //const paymentPreferences = [{ id: "1", text: "Kort", originalData: {} }, { id: "2", text: "Betalingservice", originalData: {} }]

    useEffect(() => {
        (async () => {
            //if cpr is empty, login with mitid again
            if (!props.cpr)
                window.location.href = "/member/signup";

            setLoading(true);
            try {
                let oldSignupModel: api.MemberSignup | undefined;;
                if (props.memberid && props.memberid !== '00000000-0000-0000-0000-000000000000') {
                    oldSignupModel = await api.SignupmemberService.getSignupMember(props.memberid);
                    if (!oldSignupModel.countryId)
                        oldSignupModel.countryId = defaultSignUpModel.countryId;
                }
                const countryres: api.Country[] = await api.CountriesService.getCountries();
                const newcountries = countryres.map((x) => { return { id: x.countryId!, text: x.name!, originalData: x } });
                setCountries(newcountries);
                const municipalityres: api.Municipality[] = await api.MunicipalitiesService.getMunicipalities();
                const newmuniciparities = municipalityres.map((x) => { return { id: x.municipalityid!, text: x.name!, originalData: x } });
                setMunicipalities(newmuniciparities);
                const jobtitleres: api.JobTitle[] = await api.JobtitlesService.getJobTitles();
                const newjobtitles = jobtitleres.map((x) => { return { id: x.jobTitleId!, text: x.name!, originalData: x } });
                setJobtitles(newjobtitles)

                const jobtypes: api.JobType[] = await api.JobtypesService.getJobTypes();
                const newJobTypes = jobtypes.map((x) => { return { id: x.jobTypeId!, text: x.name!, originalData: x } });
                setJobTypes(newJobTypes)

                const situationtyperes: api.SituationType[] = await api.SituationtypesService.getSituationTypes();
                const newSituationTypes = situationtyperes.map((x) => { return { id: x.situationTypeId!, text: x.name!, originalData: x } });
                setSituationTypes(newSituationTypes)

                const workplaces: api.WorkLocation[] = await api.WorklocationsService.getWorkLocations();
                const newworkplaces = workplaces.map((x) => {
                    return {
                        id: x.workLocationId!,
                        text: `${x.name!}, ${x.address?.street ? x.address.street + ' ' + (x.address.number || '') + ',' : ''} ${x.address?.zip || ""} ${x.address?.city || ""}`,
                        originalData: x
                    }
                });
                setWorkplaces(newworkplaces);

                const educationalInstitutions: api.EducationInstitution[] = await api.EducationinstitutionService.getEducationInstitution();
                const newEducationalInstitutions = educationalInstitutions.map((x) => { return { id: x.educationInsitutionId!, text: x.displayName!, originalData: x } });
                setEducationalInstitutions(newEducationalInstitutions);

                const mainsubjectres: api.EducationMainSubject[] = await api.EducationmainsubjectsService.getEducationMainSubjects();
                const newMainsubjects = mainsubjectres.map((x) => { return { id: x.educationMainSubjectId!, text: x.name!, originalData: x } });
                setMainsubjects(newMainsubjects);

                const educationalOrientations: api.EducationOrientation[] = await api.EducationorientationService.getEducationOrientation();
                const newEducationalOrientations = educationalOrientations.map((x) => { return { id: x.educationOrientationId!, text: x.name!, originalData: x } });
                setEducationalOrientations(newEducationalOrientations);
                const contactprefsres: api.CommunicationPreference[] = await api.CommunicationpreferencesService.getcommunicationPreferences();
                const newContactPrefs = contactprefsres.map((x) => { return { id: x.id!, text: x.name!, originalData: x } });
                setContactPrefs(newContactPrefs);

                const magazineData: api.Magazine[] = await api.MagazinesService.getMagazines();

                setMagazines(magazineData);
                setSignUpModel({
                    ...oldSignupModel || signUpModel, subscriptions: magazineData.map(m => 
                    {
                        return { deliveryOption: (signupElement.publications && signupElement.publications?.length > 0) ? signupElement.publications?.find(p => p.title === m.name)?.deliveryMethod as deliveryOptions : deliveryOptions.electronic, magazineId: m.magazineId! }
                    })
                });

                const arrearsAmount = oldSignupModel?.arrears || 0;
                const addingStepCount = arrearsAmount > 0 ? 1 : 0
                if (magazineData.length > 0 && signupElement.showPublications)
                    setLastStepNo(5 + addingStepCount);
                else
                    setLastStepNo(4 + addingStepCount);

            } catch (error) {
                setErrorMsg("Data kunne ikke hentes");
            }
            finally {
                setLoading(false)
            }
        })();

    }, []);

    const hasArrears = React.useMemo(() => (signUpModel.arrears || 0) > 0, [signUpModel.arrears])
    const arrearsAmount = React.useMemo(() => `${(signUpModel.arrears || 0).toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kr.`, [signUpModel.arrears])
    const arrearsDescription = React.useMemo(() => (signupElement.arrearsSetting.arrearsDescription || "").replaceAll(arrearsToken, arrearsAmount), [arrearsAmount, signupElement.arrearsSetting.arrearsDescription])

    useEffect(() => {
        const defaultlist = [signupConstants.situationtypeId]; //situationId
        const studentList = [signupConstants.startdate, signupConstants.educationInstitutionId, signupConstants.educationOrientationId]
        const inWorkList = [signupConstants.startdate, signupConstants.workLocationId, signupConstants.jobTypeId, signupConstants.jobTitleId];
        const chosenSituationEnumNo = (situationTypes.find(x => x.id === signUpModel.situationtypeId)?.originalData as api.SituationType)?.situationTypeNo;
        if (chosenSituationEnumNo === commonconstants.inJobEnumNo) {
            setWorkInfoRequired([...defaultlist, ...inWorkList])
            const newErrorlist = [...errorList].filter(x => !studentList.includes(x.name));
            setErrorList(newErrorlist);
        }
        else if (chosenSituationEnumNo === commonconstants.studentEnumNo) {
            setEducationInfoRequired(studentList);
            setWorkInfoRequired(defaultlist)
            const newErrorlist = [...errorList].filter(x => !inWorkList.includes(x.name));
            setErrorList(newErrorlist);
        }
        else {
            setWorkInfoRequired([signupConstants.startdate, ...defaultlist])//start date is always needed
            setEducationInfoRequired([])
            const newErrorlist = [...errorList].filter(x => !inWorkList.includes(x.name)).filter(x => !studentList.includes(x.name));
            setErrorList(newErrorlist);
        }
    }, [signUpModel.situationtypeId])


    const setError = (name: string, errorMsg: string) => {
        const newErrorlist = getNewErrorList([...errorList], name, errorMsg);
        if (errorList.length !== newErrorlist.length || errorList.some(e => e.name === name && e.errorMsg !== errorMsg)) {
            setErrorList(newErrorlist);
        }
    }
    
    const checkRequiredData = (required: string[], savedErrors: FormError[]) => {
        const otherErrors = errorList.filter(x => x.errorMsg !== commonconstants.requiredErrorMsg && !savedErrors.some(o => o.name === x.name));
        let checkOk = true;
        const newErrorlist: FormError[] = [];
        required.forEach((r) => {
            if (r === signupConstants.educationMainSubjectIds) {
                checkOk = (!!signUpModel.educationMainSubjectIds && signUpModel.educationMainSubjectIds.length > 0)
            }


            if (!(signUpModel as any)[r]) {
                newErrorlist.push({ name: r, errorMsg: commonconstants.requiredErrorMsg });
            }
        });
        if (signUpModel.workLocationId === commonconstants.newid) {
            if (!addedWorkplace) {
                newErrorlist.push({ name: signupConstants.unknownWorkLocation, errorMsg: commonconstants.requiredErrorMsg });
            }
        }

        const allErrorList = [...otherErrors, ...newErrorlist];
        const otherSavedErrors = savedErrors.filter(x => x.errorMsg !== commonconstants.requiredErrorMsg && !allErrorList.some(o => o.name === x.name));

        setErrorList([...allErrorList, ...otherSavedErrors]);

        return allErrorList.length === 0 && checkOk
    }
    const onSubscriptionsChanged = (changedSubscriptions: api.MagazineSubscription[]) => {
        setModel("subscriptions", changedSubscriptions);
    }

    const updateConsent = (name: string, value: boolean | undefined) => {
        if (value === undefined)
            return;

        let newSignUpModel = { ...signUpModel, consents: { ...signUpModel.consents! } };
        (newSignUpModel.consents as api.MemberConsent)[name as keyof api.MemberConsent] = value;
        setSignUpModel(newSignUpModel);
    }
    useEffect(() => {
        setModel(signupConstants.unknownWorkLocation, addedWorkplace)
    }, [addedWorkplace]);
    const setModel = (name: string, value: string | boolean | ConsentListItem[] | object | undefined) => {
        setShowErrorNotification(false);
        let newSignUpModel = { ...signUpModel };
        (newSignUpModel as any)[name] = value;
        if (name === signupConstants.situationtypeId) {
            setChosenWork(situationTypes.find(x => x.id === value))
        }
        if (name === signupConstants.countryId) {
            let requiredData = [];
            if (value as string === commonconstants.denmarkId || value as string === commonconstants.greenlandId || value === undefined) {
                requiredData = [signupConstants.mail,
                signupConstants.municipalityId,
                signupConstants.lastname,
                signupConstants.firstname,
                signupConstants.street,
                signupConstants.number,
                signupConstants.zip,
                signupConstants.cpr,
                signupConstants.mobilephone,
                signupConstants.city]
            } else {
                requiredData = [signupConstants.mail,
                signupConstants.lastname,
                signupConstants.firstname,
                signupConstants.street,
                signupConstants.number,
                signupConstants.zip,
                signupConstants.cpr,
                signupConstants.mobilephone,
                signupConstants.city]
            }
            setRequireFirstStepData(requiredData);

            // Default value is denmark but is set as undefined
            newSignUpModel = { ...signUpModel, countryId: value === undefined ? commonconstants.denmarkId : value as string, municipalityId: undefined }
        }
        setSignUpModel(newSignUpModel);
    }
    const submitForm = async () => {
        const newSignUpModel = { ...signUpModel };
        //CRM doesn't want "-" in cprnumber. Removing "-" before sending.
        newSignUpModel.cpr = signUpModel.cpr.replace("-", "");
        await api.SignupmemberService.signupMember(newSignUpModel);
    }

    const close = () => {
        window.location.href = "/Member/ReChallengeAuth"
    }

    const formsteps: FormSteps = {
        title: signupElement.title || "",
        helptext: signupElement.helpText || "",
        previousLabel: "Forrige side",
        nextLabel: "Næste trin",
        submitString: "Godkend og indsend",
        lastStepNo: lastStepNo!,
        steps: [
            {
                stepNo: 0 + (hasArrears ? 1 : 0),
                required: hasArrears ? [signupConstants.confirmedPayment] : [],
                formdata: <PreSignupStep
                    signUpModel={signUpModel}
                    setError={setError}
                    errorList={errorList}
                    arrearsTitle={signupElement.arrearsSetting.arrearsTitle}
                    arrearsDescription={arrearsDescription}
                    arrearsCheckboxLabel={signupElement.arrearsSetting.arrearsCheckboxLabel}
                    handleChange={setModel} />
            },
            {
                stepNo: 1 + (hasArrears ? 1 : 0),
                required: requireFirstStepData,
                formdata: <SignupFirstStep
                    countries={countries}
                    municipalities={municipalities}
                    signUpModel={signUpModel}
                    setError={setError}
                    loading={loading}
                    errorList={errorList}
                    handleChange={setModel}></SignupFirstStep>
            }, {
                stepNo: 2 + (hasArrears ? 1 : 0),
                required: workInfoRequired,
                formdata: <SignupThirdStep
                    setIsStudent={setIsStudent}
                    isStudent={isStudent}
                    errorList={errorList}
                    loading={loading}
                    setError={setError}
                    jobtypes={jobtypes}
                    chosenWork={chosenWork}
                    jobtitles={jobtitles}
                    situationTypes={situationTypes}
                    workplaces={workplaces}
                    handleChange={setModel}
                    addedWorkplace={addedWorkplace}
                    setAddedWorkplace={setAddedWorkplace}
                    elementdata={signupElement}
                    signUpModel={signUpModel}></SignupThirdStep>
            }, {
                stepNo: 3 + (hasArrears ? 1 : 0),

                required: educationInfoRequired,
                formdata: <SignupFourthStep
                    isStudent={isStudent}
                    errorList={errorList}
                    setError={setError}
                    signUpModel={signUpModel}
                    educationalInstitutions={educationalInstitutions}
                    educationalOrientations={educationalOrientations}
                    mainSubjects={mainsubjects}
                    handleChange={setModel}></SignupFourthStep>
            },
            {
                stepNo: 4 + (hasArrears ? 1 : 0),
                required: [],
                formdata:
                    <>{
                        magazines.length > 0 && signupElement.showPublications ?
                            <SignupFifthStep
                                errorList={errorList}
                                handleChange={() => { }}
                                setError={setError}
                                signUpModel={signUpModel}
                                conactpreferences={contactPrefs}
                                onDefaultSubscriptionsChanged={onSubscriptionsChanged}
                                deliveryUmbracoData={signupElement.deliveryForm}
                                magazines={magazines}
                            ></SignupFifthStep>
                            : <ConsentForm consentlist={signupElement.consentList} value={signUpModel.consents} consentText={signupElement.consentText} handleChange={updateConsent}></ConsentForm>
                    }</>
            },
            //{
            //    stepNo: 4,
            //    formdata: <SignupSixthStep signUpModel={signUpModel} paymentPreferences={paymentPreferences} handleChange={setModel}></SignupSixthStep>
            //},
            {
                stepNo: 5 + (hasArrears ? 1 : 0),
                formdata: <ConsentForm consentlist={signupElement.consentList} value={signUpModel.consents} consentText={signupElement.consentText} handleChange={updateConsent}></ConsentForm>
            }
        ],
        confirmationPage:
            <Confirmation
                buttonLabel={"Forsæt til minside"}
                title="Tak for din tilmelding"
                close={hasArrears ? undefined : close}
                confirmationText={hasArrears ? signupElement.arrearsSetting.arrearsConfirmationText : signupElement.confirmationText}
                contentElement={<div></div>} />,
    }

    return (
        <Wizard
            errorMsg={errorMsg}
            setShowErrorNotification={setShowErrorNotification}
            showErrorNotification={showErrorNotification}
            clearErrorMsg={() => setErrorMsg("")}
            checkRequiredData={checkRequiredData}
            confirmed={(signUpModel.consents?.acceptedTerms && signUpModel.consents.acceptedStorageOfData) || false}
            submit={submitForm}
            navigationSettings={{showBackBar: false}}
            errorList={errorList}
            data={formsteps} />
    )
}
export default SignupForm;